import request from "@/utils/request";

//
export function getCommonTitleList() {
  return request({
    method: "get",
    url: "/pc/baseInfo/getCommonTitleList",
  });
}
//获取首页办事大厅数据
export function getBanshi() {
  return request({
    method: "get",
    url: "/pc/baseInfo/getServiceHallGroupList?topFlag=2",
  });
}
export function getLoveLink() {
  return request({
    method: "get",
    url: "/pc/baseInfo/getFriendWebsiteList",
  });
}
//查新信息动态
export function getInfoList(pageNum, pageSize) {
  return request({
    method: "get",
    url:
      "/pc/baseInfo/getMessageTrendsList?pageNum=" +
      pageNum +
      "&pageSize=" +
      pageSize,
  });
}
export function searchInfoList(pageNum, pageSize, title) {
  return request({
    method: "get",
    url:
      "/pc/baseInfo/getMessageTrendsList?pageNum=" +
      pageNum +
      "&pageSize=" +
      pageSize +
      "&title=" +
      title,
  });
}
//​/获取信息动态详情

export function getInfoDetail(id) {
  return request({
    method: "get",
    url: "/pc/baseInfo/getMessageTrendsInfo/" + id,
  });
}
//获取政策宣传详情
export function getZCDetail(id) {
  return request({
    method: "get",
    url: `/pc/baseInfo/getPolicyInfo/${id}`,
  });
}
//政府宣传
export function getZfxc() {
  return request({
    method: "get",
    url: `/pc/baseInfo/getPolicyList`,
  });
}
