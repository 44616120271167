import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/Home",
    children: [
      //首页
      {
        path: "/Home",
        name: "Home",
        component: () => import("@/views/Home/HomeView"),
      },
      {
        path: "/HomeSerachinfo",
        name: "HomeSerachinfo",
        component: () => import("@/views/Home/HomeSerachinfo"),
      },
      {
        path: "/HomeViewinfo",
        name: "HomeViewinfo",
        component: () => import("@/views/Home/HomeViewinfo"),
      },
      {
        path: "/HomeDetail",
        name: "HomeDetail",
        component: () => import("@/views/Home/HomeDetail"),
      },
      {
        path: "/HomeZcDetail",
        name: "HomeZcDetail",
        component: () => import("@/views/Home/HomeZcDetail"),
      },
      //个人中心
      {
        path: "/Person",
        name: "Person",
        component: () => import("@/views/Person"),
        children: [
          {
            path: "/myinfo",
            name: "myinfo",
            component: () => import("@/views/Person/myinfo"),
          },
          {
            path: "/mynews",
            name: "mynews",
            component: () => import("@/views/Person/mynews"),
          },
          {
            path: "/myorder",
            name: "myorder",
            component: () => import("@/views/Person/myorder"),
          },
        ],
      },
      //AI普法
      {
        path: "/AIPufa",
        name: "AIPufa",

        component: () => import("@/views/AI/AIPufa"),
      },
      //入驻机构
      {
        path: "/ruzhujigou",
        name: "ruzhujigou",
        component: () => import("@/views/ruzhujigou/ruzhujigou"),
      },
      {
        path: "/ruzhujigouDetail",
        name: "ruzhujigouDetail",
        component: () => import("@/views/ruzhujigou/ruzhujigouDetail"),
      },
      //司法信息公开
      {
        path: "/Judicialinformation",
        name: "Judicialinformation",
        component: () => import("@/views/Xinxi/Judicialinformation"),
      },
      //律师咨询
      {
        path: "/lvshizixun",
        name: "lvshizixun",
        component: () => import("@/views/ziyuan/lvshizixun"),
      },
      //法律资源库
      {
        path: "/LegalResource",
        name: "LegalResource",
        redirect: "/lvshi",
        component: () => import("@/views/ziyuan/LegalResource"),
        children: [
          //律师库
          {
            path: "/lvshi",
            name: "lvshi",
            component: () => import("@/views/ziyuan/lvshi"),
            children: [
              {
                path: "/lvshi",
                redirect: "/lvshi/lvshiinfo/-1",
                component: () => import("@/views/ziyuan/lvshiinfo"),
              },
              {
                path: "/lvshi",
                redirect: "/lvshi/lvshiinfo/-1",
                component: () => import("@/views/ziyuan/lvshiinfo"),
              },
              {
                path: "/lvshi/lvshiinfo/:dictSort",
                name: "lvshinfo",
                component: () => import("@/views/ziyuan/lvshiinfo"),
              },
            ],
          },
          //律所库
          {
            path: "/lvsuo",
            name: "lvsuo",
            component: () => import("@/views/ziyuan/lvsuo"),
            children: [
              {
                path: "/",
                redirect: "/lvsuo/lvsuoinfo/-1",
                component: () => import("@/views/ziyuan/lvsuoinfo"),
              },
              {
                path: "/",
                redirect: "/lvsuo/lvsuoinfo/-1",
                component: () => import("@/views/ziyuan/lvsuoinfo"),
              },
              {
                path: "lvsuoinfo/:key",
                name: "lvsuoinfo",
                component: () => import("@/views/ziyuan/lvsuoinfo"),
              },
            ],
          },
          //公证
          {
            path: "/gongzheng",
            name: "gongzheng",
            component: () => import("@/views/ziyuan/gongzheng"),
            children: [
              {
                path: "/",
                redirect: "/gongzheng/gongzhenginfo/-1",
                component: () => import("@/views/ziyuan/gongzhenginfo"),
              },
              {
                path: "/",
                redirect: "/gongzheng/gongzhenginfo/-1",
                component: () => import("@/views/ziyuan/gongzhenginfo"),
              },
              {
                path: "gongzhenginfo/:key",
                name: "gongzhenginfo",
                component: () => import("@/views/ziyuan/gongzhenginfo"),
              },
            ],
          },
          // 法律库
          {
            path: "/falv",
            name: "falv",
            component: () => import("@/views/ziyuan/falv"),
            children: [
              {
                path: "/",
                redirect: "/falv/falvinfo/-1",
                component: () => import("@/views/ziyuan/falvinfo"),
              },
              {
                path: "falvinfo/:key",
                name: "falvinfo",
                component: () => import("@/views/ziyuan/falvinfo"),
              },
              {
                path: "falvSearchinfo",
                name: "falvSearchinfo",
                component: () => import("@/views/ziyuan/falvSearchinfo"),
              },
            ],
          },
          //心理咨询师库
          {
            path: "/xinli",
            name: "xinli",
            component: () => import("@/views/ziyuan/xinli"),
            children: [
              {
                path: "/",
                redirect: "/xinli/xinliinfo/-1",
                component: () => import("@/views/ziyuan/xinliinfo"),
              },
              {
                path: "/",
                redirect: "/xinli/xinliinfo/-1",
                component: () => import("@/views/ziyuan/xinliinfo"),
              },
              {
                path: "xinliinfo/:key",
                name: "xinliinfo",
                component: () => import("@/views/ziyuan/xinliinfo"),
              },
            ],
          },
          // 合同库
          {
            path: "/hetong",
            name: "hetong",
            component: () => import("@/views/ziyuan/hetong"),
            children: [
              {
                path: "/",
                redirect: "/hetong/hetonginfo/-1",
                component: () => import("@/views/ziyuan/hetonginfo"),
              },
              {
                path: "hetonginfo/-1",
                name: "hetonginfo",
                component: () => import("@/views/ziyuan/hetonginfo"),
              },
              {
                path: "hetonginfo",
                name: "hetonginfo",
                component: () => import("@/views/ziyuan/hetonginfo"),
              },
              {
                path: "hetonginfo/qiye/:key",
                name: "hetonginfo",
                component: () => import("@/views/ziyuan/hetonginfo"),
              },
              {
                path: "hetonginfo/geren/:key",
                name: "hetonginfo",
                component: () => import("@/views/ziyuan/hetonginfo"),
              },
            ],
          },
          {
            path: "/sz",
            name: "sz",
            component: () => import("@/views/ziyuan/sz"),
            children: [
              {
                path: "/",
                redirect: "/sz/szinfo/-1",
                component: () => import("@/views/ziyuan/szinfo"),
              },
              {
                path: "szinfo/-1",
                name: "szinfo",
                component: () => import("@/views/ziyuan/szinfo"),
              },
              {
                path: "szinfo/:key",
                name: "szinfo",
                component: () => import("@/views/ziyuan/szinfo"),
              },
            ],
          },
        ],
      },
      // 律师咨询
      {
        path: "/lvshizixun/:key",
        name: "lvshizixun",
        component: () => import("@/views/ziyuan/lvshizixun"),
      },
      //律师详情
      {
        path: "/lvshiDetail",
        name: "lvshiDetail",
        component: () => import("@/views/ziyuan/lvshiDetail"),
      },
      //律所详情
      {
        path: "/lvsuoDetail",
        name: "lvsuoDetail",
        component: () => import("@/views/ziyuan/lvsuoDetail"),
      },
      //公证员详情
      {
        path: "/gongzhengDetail",
        name: "gongzhengDetail",
        component: () => import("@/views/ziyuan/gongzhengDetail"),
      },
      //心理咨询师详情
      {
        path: "/xinliDetail",
        name: "xinliDetail",
        component: () => import("@/views/ziyuan/xinliDetail"),
      },
      //法律服务
      {
        path: "/LegalService",
        name: "LegalService",
        component: () => import("@/views/Falvfuwu/LegalService"),
      },
      //普法课堂
      {
        path: "/PufaKetang",
        name: "PufaKetang",
        component: () => import("@/views/Ketang/PufaKetang"),
        children: [
          // 重定向为普法短视频
          {
            path: "/",
            redirect: "/PuFaVideos",
            component: () => import("@/views/Ketang/PuFaVideos"),
          },
          // 普法短视频
          {
            path: "/PuFaVideos",
            name: "PuFaVideos",
            component: () => import("@/views/Ketang/PuFaVideos"),
          },
          // 普法文章
          {
            path: "/PuFaArticle",
            name: "PuFaArticle",
            component: () => import("@/views/Ketang/PuFaArticle"),
          },

          // 普法案例
          {
            path: "/PuFaCase",
            name: "PuFaCase",
            component: () => import("@/views/Ketang/PuFaCase"),
          },
        ],
      },
      // 普法案例详情

      {
        path: "/CaseDetail",
        name: "CaseDetail",
        component: () => import("@/views/Ketang/CaseDetail"),
      },
      // 普法课堂详情
      {
        path: "/ketangDetail",
        name: "ketangDetail",
        component: () => import("@/views/Ketang/ketangDetail"),
      },
      //办事大厅
      {
        path: "/ServiceHall",
        name: "ServiceHall",
        component: () => import("@/views/Banshi/ServiceHall"),
      },
      {
        path: "/ServiceHallinfo",
        name: "ServiceHallinfo",
        component: () => import("@/views/Banshi/ServiceHallinfo"),
      },
      {
        path: "/SearchService",
        name: "SearchService",
        component: () => import("@/views/Banshi/SearchService"),
      },
      //办事大厅详情
      {
        path: "/ServiceHallDetail",
        name: "ServiceHallDetail",
        component: () => import("@/views/Banshi/ServiceHallDetail"),
      },
      //政策宣传
      {
        path: "/ZhengCeXuanchuan",
        name: "ZhengCeXuanchuan",
        component: () => import("@/views/zhengce/ZhengCeXuanchuan"),
      },
      //政策宣传详情
      {
        path: "/ZhengCeXuanChuanDetail",
        name: "ZhengCeXuanChuanDetail",
        component: () => import("@/views/zhengce/ZhengCeXuanChuanDetail"),
      },
    ],
  },
  {
    path: "/show",
    component: () => import("@/views/show"),
  },
  {
    path: "/zixunxuzhi",
    component: () => import("@/views/zixunxuzhi"),
  },
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: LoginView
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
