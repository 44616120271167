import request from "@/utils/request";
//查询个人信息
export function getmyinfo(token) {
  console.log(token);
  return request({
    method: "get",
    url: `/system/user/profile`,
    headers: { Authorization: "Bearer " + token },
  });
}

///pc/selfInfo/getMessageList
//查询我的消息,需要传入联系电话
export function getmynews(telphone,token,pageNum,pageSize) {
  return request({
    method: "get",
    url: `pc/selfInfo/getMessageList?linkPhone=${telphone}&pageNum=${pageNum}&pageSize=${pageSize}`,
    headers: { Authorization: "Bearer " + token },
  });
}
//获取我的订单信息
export function getmyorders(telphone,token,pageNum,pageSize) {
  return request({
    method: "get",
    url: `/pc/selfInfo/getConsultList?userPhone=${telphone}&pageNum=${pageNum}&pageSize=${pageSize}`,
    headers: { Authorization: "Bearer " + token },
  });
}
//修改个人信息
// /system/user/profile

export function editprofile(data,token) {
  return request({
    method: "put",
    url: `/system/user/profile/`,
    data:data,
    headers: { Authorization: "Bearer " + token },
  });
}
//修改密码
export function editpsw(oldPassword,newPassword,token) {
  return request({
    method: "put",
    url: `/system/user/profile/updatePwd?oldPassword=${oldPassword}&newPassword=${newPassword}`,
    headers: { Authorization: "Bearer " + token },
  });
}