import axios from "axios";
import _this from "@/main.js";
import { tansParams } from "./index";
import errorCode from "@/utils/errorCode";
import { getToken } from "./auth";
axios.defaults.headers["isToken"] = false;
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 对应国际化资源文件后缀
axios.defaults.headers["Content-Language"] = "zh_CN";

/* 创建实例 */
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: "https://gpzfw.pstech360.com//prod-api",
  // baseURL: "http://192.168.1.190:9201",
  baseURL: "https://gpzfw.pstech360.com/prod-api",
    //  baseURL: "/prod-api",
  // 超时
  timeout: 50000,
});
/**
 * 请求拦截
 */
service.interceptors.request.use(
  (config) => {
    // console.log(config);
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    // 是否需要防止数据重复提交
    // const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    if (getToken() && !isToken) {
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?" + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);
/* 响应拦截器 */
service.interceptors.response.use(
  (res) => {
    // console.log("resres", res);
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    console.log("code", code);
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    // 二进制数据则直接返回
    if (
      res.request.responseType === "blob" ||
      res.request.responseType === "arraybuffer"
    ) {
      return res.data;
    }
    if (code == 401) {
      _this.$message.error("认证失败，请重新登录");
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
      localStorage.removeItem("userinfo");
      setTimeout(() => {
        _this.$router.push("/");
        location.reload();
      }, 1000);
      return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
    } else if (code === 404) {
      console.log(msg);
      return Promise.reject(msg);
    } else if (code !== 200) {
      console.log(msg);
      return Promise.reject(msg);
    } else {
      return res.data;
    }
  },
  (error) => {
    console.log("err" + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    console.log(message);
    return Promise.reject(error);
  }
);

export default service;
