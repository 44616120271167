<template>
  <div class="mains">
    <div class="footer-box">
      <div class="bottom-left">
        <img :src="this.footerlogo" alt="" />
        <div class="left-right">
          <p>{{ footerFirsttitle }}</p>
          <div style="display: flex">
            <p>{{ footerSectitleleft }}</p>
            <p @click="openbeian" class="beian">
              {{ footerSectitleright }}
            </p>
          </div>
        </div>
      </div>
      <div class="qrcode">
        <img :src="this.qrcode" alt="" />
        <p>{{ qrtitle }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getCommonTitleList } from "@/api/home";
export default {
  name: "Footer",
  data() {
    return {
      logotitle: "",
      logo: "",
      qrcode: "",
      footerlogo: "",
      footerFirsttitle: "",
      footerSectitle: "",
      footerSectitleleft: "",
      footerSectitleright: "",

      qrtitle: "",
    };
  },
  mounted() {
    this.getCommonTitleListss();
  },
  methods: {
    openbeian() {
      window.open("https://beian.miit.gov.cn/");
    },
    getCommonTitleListss() {
      getCommonTitleList()
        .then((res) => {
          console.log("logo", res);
          res.data.forEach((item) => {
            if (item.type == "2") {
              this.footerlogo = this.$baseurl + item.url;
              this.footerFirsttitle = item.firstTitle;
              this.footerSectitle = item.secondTitle;
              let index = this.footerSectitle.lastIndexOf(" ");
              this.footerSectitleleft = this.footerSectitle.substring(0, index);
              this.footerSectitleright = this.footerSectitle.substring(index);
            } else {
              this.qrcode = this.$baseurl + item.url;
              this.qrtitle = item.firstTitle;
            }
          });
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.beian {
  margin-left: 8px;
}

.beian:hover {
  color: red;
  cursor: pointer;
  text-decoration: underline;
}

.mains {
  width: 100%;
  height: 150px;
  background: #0822a2;
  margin-top: 30px;

  .footer-box {
    width: 1300px;
    height: 68px;
    margin: auto;
    padding: 36px 0 36px 0;
    display: flex;
    justify-content: space-between;

    .bottom-left {
      display: flex;

      img {
        width: 56px;
        height: 68px;
      }
    }

    .left-right {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      p {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #d9dceb;
      }
    }

    .qrcode {
      display: flex;
      flex-direction: column;
      text-align: right;

      img {
        display: block;
        width: 72px;
        height: 72px;
        margin: 0 auto;
      }

      p {
        margin-top: 10px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #d9dceb;
      }
    }
  }
}
</style>
