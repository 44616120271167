<template>
  <div class="containout">
    <Head :child-message="childMessage"></Head>
    <div :class="falv != true ? 'main' : 'flmain'"><router-view /></div>
    <Footer></Footer>
  </div>
</template>
<script>
import Head from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "AbHomeLayout",
  components: { Head, Footer },

  data() {
    return {
      childMessage: "",
      falv: false,
    };
  },
  mounted() {
    if (this.$route.name === "falvinfo") {
      this.falv = true;
    }
  },
  watch: {
    $route(newRoute) {
      console.log("路由变化了", newRoute);
      if (newRoute.name === "falvinfo") {
        this.falv = true;
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.containout {
  width: 100%;
  background: url("../assets/images/bg_footer@2x.png") no-repeat;
  background-size: 100% 100%;

  .main {
    width: 1300px;
    // min-height: 1060px;
    height: auto;
    margin: auto;
  }
  .flmain {
    width: 1300px;
    min-height: 1060px;
    height: auto;
    margin: auto;
  }
}
</style>
