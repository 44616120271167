<!-- eslint-disable no-undef -->
<template>
  <header class="all">
    <div class="header">
      <!-- 登录/注册弹框 -->
      <el-drawer :visible.sync="drawer" direction="rtl" :beforae-close="handleClose" size="619px">
        <div class="login-box" v-if="logined === 'changePass'">
          <h1 style="
              margin: 0 auto;
              margin-top: 56px;
              margin-bottom: 55px;
              text-align: center;
            ">
            修改密码
          </h1>
          <div class="form">
            <el-form label-width="108px" :model="form" :rules="formrules" label-position="left" ref="updaterules">
              <el-form-item label="手机号" prop="phonenumber" label-width="108px">
                <el-input v-model="form.phonenumber" style="width: 100%; height: 61px" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="smsCode" label-width="108px">
                <el-input v-model="form.smsCode" style="width: 100%; height: 61px" placeholder="请输入短信验证码"></el-input>
                <el-button class="sign-code" style="
                    color: #ffffff;
                    float: right;
                    position: relative;
                    margin-top: -60px;
                    color: #ffffff;
                  " @click="getmsmcodes(form.phonenumber)" v-if="timeOn == 0">发送验证码</el-button>
                <el-button class="sign-code" style="
                    float: right;
                    position: relative;
                    margin-top: -60px;
                    color: #ffffff;
                  " disabled v-else>请稍后{{ timeOn }}S</el-button>
              </el-form-item>
              <el-form-item label="密码" prop="updatePassword" label-width="108px">
                <el-input v-model="form.updatePassword" style="width: 100%" placeholder="请输入密码"
                  show-password></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="repassword" label-width="108px">
                <el-input v-model="form.repassword" style="width: 100%" placeholder="请输入密码" show-password></el-input>
              </el-form-item>
              <div style="
                  display: flex;
                  flex-direction: column;
                  margin-top: 119px;
                  align-items: center;
                ">
                <el-button style="margin-left: 10px" class="bottom-btn" @click="update()">保存提交</el-button>
                <el-button style="margin-left: 10px; margin-top: 20px" class="code-btn" @click="
                  logined = 'comlogin';
                form = {};
                ">点我登录</el-button>
              </div>
            </el-form>
          </div>
        </div>
        <div class="login-box" v-if="logined === 'comlogin'">
          <h1 style="
              margin: 0 auto;
              margin-top: 56px;
              margin-bottom: 55px;
              text-align: center;
            ">
            登录
          </h1>

          <div class="form">
            <el-form label-width=" 108px" :model="form" :rules="formrules" label-position="left" ref="formrules">
              <el-form-item label="手机号" prop="mobile" label-width="108px">
                <el-input v-model="form.mobile" style="width: 100%; height: 61px" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="passWord" label-width="108px">
                <el-input v-model="form.passWord" style="width: 100%" placeholder="请输入密码" show-password></el-input>
              </el-form-item>
              <div class="losspass" @click="
                logined = 'changePass';
              form = {};
              ">
                忘记密码?
              </div>
              <div style="
                  display: flex;
                  flex-direction: column;
                  margin-top: 119px;
                  align-items: center;
                ">
                <el-button style="margin-left: 10px" class="bottom-btn" @click="logins">登录</el-button>
                <el-button @click="logined = 'codelogin'" class="code-btn">短信验证码登录</el-button>
              </div>
              <div class="bottom-text">
                没有账号?
                <span @click="
                  logined = 'signlogin';
                form = {};
                ">立即注册</span>
              </div>
            </el-form>
          </div>
        </div>
        <div class="logined-code" v-if="logined === 'codelogin'">
          <h1 style="
              margin: 0 auto;
              margin-top: 56px;
              margin-bottom: 55px;
              text-align: center;
            ">
            登录
          </h1>
          <div class="form">
            <el-form :model="form" label-width=" 108px" label-position="left" ref="codeforms" :rules="formrules">
              <el-form-item label="手机号" prop="mobile" label-width="108px">
                <el-input v-model="form.mobile" style="width: 100%; height: 61px" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="smsCode" label-width="108px">
                <el-input v-model="form.smsCode" style="width: 100%" placeholder="请输入6位验证码"></el-input>
              </el-form-item>
              <el-button class="sign-code" style="
                  color: #ffffff;
                  float: right;
                  position: relative;
                  margin-top: -82px;
                " @click="getmsmcodes(form.mobile)" v-if="timeOn == 0">发送验证码</el-button>
              <el-button class="sign-code" style="
                  float: right;
                  position: relative;
                  margin-top: -82px;
                  color: #ffffff;
                " disabled v-else>请稍后{{ timeOn }}S</el-button>
              <!-- <el-button class="code-go" style="" @click="getmsmcodes(form.telPhone)"
                v-if="timeOn == 0">发送验证码</el-button>
              <el-button class="code-go" style="background-color: rgba(128,128,128,0.3);color: black;" :disabled="true"
                v-else>请等待{{ timeOn }}S</el-button> -->
              <div style="
                  display: flex;
                  flex-direction: column;
                  margin-top: 119px;
                  align-items: center;
                ">
                <el-button style="margin-left: 10px" class="bottom-btn" @click="loginCodes">登录</el-button>
                <el-button @click="logined = 'comlogin'" class="code-btn">密码登录</el-button>
              </div>
              <div class="bottom-text">
                没有账号?
                <span @click="
                  logined = 'signlogin';
                form = {};
                ">立即注册</span>
              </div>
            </el-form>
          </div>
        </div>
        <div class="signin-box" v-if="logined === 'signlogin'">
          <h1 style="
              margin: 0 auto;
              margin-top: 56px;
              margin-bottom: 55px;
              text-align: center;
            ">
            注册
          </h1>
          <div class="form">
            <el-form :model="form" label-width="120px" label-position="left" ref="signforms" :rules="formrules">
              <el-form-item label="手机号" prop="telPhone" label-width="120px">
                <el-input v-model="form.telPhone" style="width: 100%; height: 61px" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="昵称" prop="nickName" label-width="120px">
                <el-input v-model="form.nickName" style="width: 100%; height: 61px" placeholder="请输入昵称"></el-input>
              </el-form-item>
              <el-form-item label="短信验证码" prop="smsCode" label-width="120px">
                <el-input v-model="form.smsCode" style="width: 100%; height: 61px" placeholder="请输入短信验证码"></el-input>
                <el-button class="sign-code" style="
                    color: #ffffff;
                    float: right;
                    position: relative;
                    margin-top: -60px;
                  " @click="signgetmsmcodes(form.telPhone)" v-if="timeOn == 0">发送验证码</el-button>
                <el-button class="sign-code" style="
                    float: right;
                    position: relative;
                    margin-top: -60px;
                    color: #ffffff;
                  " disabled v-else>请稍后{{ timeOn }}S</el-button>
              </el-form-item>
              <el-form-item label="密码" prop="password" label-width="120px">
                <el-input v-model="form.password" style="width: 100%; height: 61px" placeholder="请输入密码"
                  show-password></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="repassword" label-width="120px">
                <el-input v-model="form.repassword" style="width: 100%; height: 61px" show-password
                  placeholder="请重新输入注册密码"></el-input>
              </el-form-item>
              <div class="tips">
                <input type="checkbox" style="width: 24px; height: 24px; margin-right: 10px" v-model="ischeck" />
                <span style="color: rgba(134, 140, 150, 1)">我已同意<span
                    style="line-height: 24px; color: rgba(205, 29, 29, 1)" @click="toread()">《用户隐私协议》</span></span>
              </div>
              <el-button @click="addusers()" style="
                  width: 538px;
                  font-family: PingFang SC;
                  margin-top: 24px;
                  font-weight: 500;
                  font-size: 18px;
                  color: #ffffff;
                  height: 60px;
                  background: #cd1d1d;
                ">注册</el-button>
              <div class="remeber">
                我有账号，<span style="color: rgba(205, 29, 29, 1)" @click="
                  logined = 'comlogin';
                form = {};
                ">立即登录</span>
              </div>
            </el-form>
          </div>
        </div>
      </el-drawer>
      <div class="top-logo">
        <div class="logobox">
          <img :src="this.logo" alt="" />
          <div class="logobox-right">
            <p>{{ logotitle }}</p>
            <p>{{ logosecTitle }}</p>
          </div>
        </div>
        <div v-if="cookies === false">
          <button class="btn" @click="login()">登录/注册</button>
        </div>
        <div v-else>
          <el-dropdown @command="handleCommand">
            <div class="el-dropdown-link" style="color: #ffffff; line-height: 50px; display: flex">
              <img :src="usericon" alt="" width="50" height="50" style="border-radius: 100%; margin-right: 10px" />
              <p style="height: 50px; line-height: 50px">您好,{{ username }}</p>
              <i style="line-height: 50px" class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">个人信息</el-dropdown-item>
              <el-dropdown-item command="1">我的消息</el-dropdown-item>
              <el-dropdown-item command="2">我的咨询</el-dropdown-item>
              <el-dropdown-item command="3">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="top-nav">
      <div class="nav-item">
        <router-link :to="navitem.link" class="routerbox" :key="index" active-class="active"
          v-for="(navitem, index) in list">
          <span>{{ navitem.name }}</span>
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import { getCommonTitleList } from "@/api/home";
import { getDocumentInfoList } from "@/api/dict";
import { getmyinfo, editprofile, editpsw } from "@/api/Person";
import { setToken } from "@/utils/auth";
import {
  adduser,
  getmsmcode,
  login,
  loginCode,
  ExistsUsers,
  changePass,
} from "@/api/login.js";
export default {
  name: "AbHomeHeader",
  data() {
    return {
      formrules: {
        mobile: [
          { required: true, message: "手机号不能位空", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" },
        ],
        passWord: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        telPhone: [
          { required: true, message: "手机号不能位空", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" },
        ],
        smsCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 6,
            message: "验证码必须6位且必须为数字",
            trigger: "blur",
          },
        ],
        nickName: [
          { required: true, message: "昵称不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { min: 6, max: 18, message: "密码长度在6到18位", trigger: "blur" },
        ],
        repassword: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { min: 6, max: 18, message: "密码长度在6到18位", trigger: "blur" },
        ],
        phonenumber: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { min: 11, max: 11, message: "手机号必须11位", trigger: "blur" },
        ],
        updatePassword: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { min: 6, max: 18, message: "密码长度在6到18位", trigger: "blur" },
        ],
      },
      ischeck: false,
      timer: null,
      timeOn: 0,
      current_index: 0,
      list: [
        {
          name: "首页",
          link: "/Home",
        },
        {
          name: "政策宣传",
          link: "/ZhengCeXuanchuan",
        },
        {
          name: "办事大厅",
          link: "/ServiceHall",
        },
        {
          name: "法律资源库",
          link: "/lvshi",
        },
        {
          name: "司法信息公开",
          link: "/Judicialinformation",
        },
        {
          name: "入驻机构",
          link: "/ruzhujigou",
        },
        {
          name: "普法课堂",
          link: "/PuFaVideos",
        },
        {
          name: "AI普法",
          link: "/AIPufa",
        },
        {
          name: "法律服务",
          link: "/LegalService",
        },
      ],
      nickName: "",
      title: "",
      drawer: false,
      username: "",
      telPhone: "",
      formLabelAlign: {},
      logotitle: "",
      logosecTitle: "",
      logo: "",
      qrcode: "",
      footerlogo: "",
      footerFirsttitle: "",
      footerSectitle: "",
      qrtitle: "",
      //判断用户是否登录还是注册
      logined: "comlogin",
      cookies: false,
      usericon: "",
      form: {
        code: "",
        nickName: "",
        passWord: "",
        smsCode: "",
        telPhone: "",
        username: "",
        uuid: "",
      },
    };
  },
  watch: {
    username(val) { },
    timeOn(val) {
      if (val == 0) {
        clearInterval(this.timer);
      }
    },
  },
  created() { },
  mounted() {
    console.log("我挂载了");

    this.getCommonTitleLists();
    this.getmyinfos();

    const res = document.cookie.split("token=");
    console.log("token", res);
    console.log("token", res);
    if (res.length > 1) {
      this.cookies = true;
    } else this.cookies = false;
    this.flash();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollLinsten);
  },
  methods: {
    getCommonTitleLists() {
      getCommonTitleList()
        .then((res) => {
          console.log("toplogo", res);
          res.data.forEach((item) => {
            if (item.type == "1") {
              console.log(item);
              this.logo = item.url;
              this.logotitle = item.firstTitle;
              this.logosecTitle = item.secondTitle;
              console.log(this.logotitle, this.logosecTitle);
            }
          });
        })
        .catch((err) => { });
    },
    getmyinfos() {
      const token = document.cookie.split("token=")[1].split(";")[0];
      getmyinfo(token)
        .then((res) => {
          console.log("个人信息", res);
          const { userName, nickName, phonenumber, avatar } = res.data;
          this.username = nickName;
          this.phonenumber = userName || phonenumber;
          this.telPhone = userName || phonenumber;
          console.log("tel", this.telPhone);
          this.usericon =
            avatar === "" ? require("@/assets/moren.png") : avatar;
          // localStorage.setItem("userinfo", JSON.stringify(res.data));
          // location.reload()
        })
        .catch((err) => {
          this.$message.error("认证失败请重新登录");
        });
    },
    toread() {
      // this.$router.push("/show")
      getDocumentInfoList()
        .then((res) => {
          res.rows.forEach((item) => {
            if (item.type === "1") {
              console.log("item", item.content);
              // item.content;
              this.$alert(item.content, "用户隐私协议", {
                customClass: "elmessageWidth",
                dangerouslyUseHTMLString: true,
                confirmButtonText: "已知晓",
                callback: (action) => {
                  this.ischeck = true;
                },
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    update() {
      console.log(this.form);
      const ispwd =
        this.form.updatePassword === this.form.repassword ? true : false;
      if (ispwd) {
        this.$refs["updaterules"].validate((valid) => {
          if (valid) {
            const { phonenumber, smsCode, updatePassword } = this.form;
            changePass(phonenumber, smsCode, updatePassword)
              .then((res) => {
                console.log(res);
                this.$message.success("修改成功,请去登录");
                setTimeout(() => {
                  this.logined = "comlogin";
                  this.form = {};
                }, 300);
              })
              .catch((err) => {
                this.$message.error(err);
              });
          } else {
            this.$message.error("请填写正确信息");
          }
        });
      } else {
        this.$message.error("两次密码不一致");
      }
    },
    changeIndex(index) {
      this.current_index = index;
    },
    flash() {
      const path = location.pathname;
      this.list.forEach((item, index) => {
        if (path.indexOf(item.link) != -1) {
          this.current_index = index;
        }
      });
    },
    setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    //二维码登录
    async loginCodes() {
      //当登录表单验证通过时，调用login方法
      this.$refs["codeforms"].validate((valid) => {
        if (valid) {
          ExistsUsers(this.form.mobile)
            .then((res) => {
              const { mobile, smsCode } = this.form;
              const form = { mobile, smsCode };
              loginCode(form)
                .then((res) => {
                  // document.cookie = "token=" + res.token;
                  this.setCookie("token", res.token, 1);

                  console.log(res.userInfo);
                  localStorage.setItem(
                    "userinfo",
                    JSON.stringify(res.userInfo)
                  );
                  this.username = res.userInfo.userName;
                  console.log(res);
                  console.log("username", this.username);
                  this.cookies = true;
                  this.drawer = false;
                  this.$message.success("登录成功");
                  this.$router.push("/Home");
                  location.reload();
                })
                .catch((err) => {
                  console.log(err);
                  this.$message.error(err);
                });
            })
            .catch((err) => {
              console.log("手机号未注册,请先去注册");
            });
        } else {
          this.$message.error("请填写正确信息");
          return false;
        }
      });
    },
    //登录按钮
    async logins() {
      //当登录表单验证通过时，调用login方法
      this.$refs["formrules"].validate((valid) => {
        if (valid) {
          console.log(this.form);
          ExistsUsers(this.form.mobile)
            .then((res) => {
              login(this.form)
                .then((res) => {
                  if (res.code == 200) {
                    console.log("login", res);
                    // document.cookie = "token=" + res.token;
                    this.setCookie("token", res.token, 1);
                    this.username = res.data.userName;
                    localStorage.setItem("userinfo", JSON.stringify(res.data));
                    console.log(res);
                    console.log("username", this.username);
                    this.cookies = true;
                    this.drawer = false;
                    this.$message.success("登录成功");
                    this.$router.push("/Home");
                    location.reload();
                  }
                })
                .catch((err) => {
                  this.$message.error(err);
                });
            })
            .catch((err) => {
              this.$message.error(err);
            });
        } else {
          this.$message.error("请填写正确信息");
          return false;
        }
      });
    },
    //退出登录
    handleCommand(command) {
      console.log(command);
      if (command == "3") {
        console.log("tuichu");
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
        localStorage.removeItem("userinfo");
        // location.href = "http://gpzfw.pstech360.com/";
        this.$router.push("/Home");
        setTimeout(() => {
          location.reload();
        }, 100);
      } else if (command == "0") {
        this.$router.push("/myinfo");
      } else if (command == "1") {
        const telPhone = this.telPhone;
        console.log("tel", telPhone);
        this.$router.push({
          path: `/mynews`,
          query: {
            telphone: telPhone,
          },
        });
      } else {
        console.log("zixun");
        const telPhone = this.telPhone;
        this.$router.push({
          path: "/myorder",
          query: {
            telphone: telPhone,
          },
        });
      }
    },
    //注册按钮
    sign() { },
    login() {
      this.drawer = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    async addusers() {
      //当注册表单验证通过时，调用adduser方法
      //判断用户是否已经注册
      console.log("form", this.form.password, this.form.repassword);
      // ExistsUsers
      this.$refs["signforms"].validate(async (valid) => {
        if (this.ischeck) {
          if (valid) {
            const isPwd =
              this.form.password == this.form.repassword ? true : false;
            if (isPwd) {
              ExistsUsers(this.form.telPhone)
                .then((res) => {
                  console.log("res", res);
                  this.$message.error("手机号已注册");
                })
                .catch((err) => {
                  console.log("err", err);
                  adduser(this.form).then((res) => {
                    this.$message.success("注册成功,");
                    this.drawer = false;
                    this.$router.push("/Home");
                  });
                });
            } else {
              this.$message.error("两次密码不一致");
            }
          } else {
            this.$message.error("请填写正确信息");
          }
        } else {
          this.$message.error("请先同意用户隐私秘密协议");
        }
      });
    },
    //注册获取验证码
    signgetmsmcodes(telPhone) {
      if (this.timeOn == 0) {
        getmsmcode(telPhone)
          .then((res) => {
            console.log("ok", res);
            this.$message.success("发送成功,请注意手机短信");
            this.timeOn = 60;
            this.timer = setInterval(() => {
              this.timeOn--;
            }, 1000);
          })
          .catch((err) => {
            console.log("err", err);
            this.$message.error(err);
          });
      }
    },
    //获取验证码
    async getmsmcodes(telPhone) {
      ExistsUsers(telPhone)
        .then((res) => {
          if (this.timeOn == 0) {
            getmsmcode(telPhone)
              .then((res) => {
                console.log("ok", res);
                this.$message.success("发送成功,请注意手机短信");
                this.timeOn = 60;
                this.timer = setInterval(() => {
                  this.timeOn--;
                }, 1000);
              })
              .catch((err) => {
                console.log("err", err);
                this.$message.error(err);
              });
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.logobox {
  // background-color: #202227;
  display: flex;

  img {
    width: 68px;
    height: 68px;
  }

  .logobox-right {
    margin-left: 20px;
    display: flex;
    flex-direction: column;

    p {
      color: #fff;
      font-size: 22px;
    }
  }
}

::v-deep .el-input__inner {
  height: 61px;
}

::v-deep .el-form-item__label {
  height: 61px;
  line-height: 61px;
  line-height: 61px;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #202227;
}

.all {
  width: 100%;
  // margin: auto;
}

.header {
  height: 68px;
  // background-color: red;
  padding-top: 17px;
  // padding-left: 310px;
  // padding-right: 331px;
  justify-content: flex-start;
  display: flex;

  //
  .top-logo {
    width: 1300px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }

  .login-box {
    z-index: 99999;
  }

  //登录页面的样式
  .login-box,
  .logined-code,
  .signin-box {
    .form {
      margin-top: 55px;
      margin: 0 auto;
      text-align: center;
      width: 539px;

      .signitem {
        display: flex;
        margin: 20px 0px;
      }

      .remeber {
        margin-top: 35px;
      }

      .remeber:hover {
        cursor: pointer;
      }

      .tips {
        display: flex;
        align-items: center;
        height: 24px;
        text-align: left;
        margin-top: 10%;
        margin-top: 24px;
        padding-top: 40px;

        span {
          display: inline-block;
          height: 24px;
          line-height: 24px;
        }
      }

      .sign-code {
        width: 142px;
        height: 60px;
        background: #cd1d1d;
      }

      .label {
        margin-right: 15px;
        width: 120px;
        height: 61px;
        line-height: 61px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #202227;
      }

      .losspass {
        float: right;
        // margin-top: -40px;
        width: 100px;
        height: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #868c96;
      }

      .losspass:hover {
        cursor: pointer;
      }

      .input {
        width: 471px;
        height: 61px;
        background: #ffffff;
        border: 2px solid #bcc2cf;
        padding-left: 16px;
      }

      .bottom-btn {
        text-align: center;
        width: 538px;
        height: 60px;
        background: #cd1d1d;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
      }

      .code-go {
        width: 142px;
        height: 60px;
        background: #cd1d1d;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        position: relative;
        top: -82px;
        right: -200px;
      }

      .bottom-text {
        margin: 0 auto;
        text-align: center;
        margin-top: 80px;
        width: 162px;
        height: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #868c96;

        span {
          color: rgba(205, 29, 29, 1);
        }

        span:hover {
          cursor: pointer;
        }
      }

      .code-btn {
        margin-top: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #cd1d1d;
        text-align: center;
        width: 539px;
        height: 61px;
        background: #fff5f5;
        border: 1px solid #cd1d1d;
      }
    }
  }

  .btn {
    // margin-left: 976px;
    margin-top: 10px;
    width: 124px;
    height: 44px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 22px;
    border: 1px solid #d3e0ff;
    color: #fff;
  }
}

.top-nav {
  display: flex;
  margin-top: 16px;
  width: 100%;
  height: 60px;
  background-color: rgba(148, 205, 255, 0.3);
  // background: #6569b1;

  // opacity: 0.3;
  .nav-item {
    display: flex;
    justify-content: space-around;
    // padding-left: 310px;
    align-items: center;
    width: 1300px;
    margin: auto;
    height: 60px;

    .routerbox {
      width: 144px;
      height: 60px;
      text-decoration: none;
      display: flex;

      span {
        text-align: center;
        // width: 35px;
        // height: 17px;
        font-family: PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #ffffff;
        line-height: 60px;
        display: block;
        margin: 0 auto;
      }
    }

    .active {
      background: rgba(148, 205, 255, 0.3);
    }

    .routerbox:hover {
      background: rgba(148, 205, 255, 0.3);
    }
  }
}
</style>
