import request from "@/utils/request";
//查询字典
export function getDict(dictType) {
  return request({
    method: "get",
    url: "/pc/baseInfo/dictType/" + dictType,
  });
}

//获取隐私保护信息
export function getDocumentInfoList() {
  return request({
    method: "get",
    url: "/pc/selfInfo/getDocumentInfoList",
  });
}
