import request from "@/utils/request";
//根据有二维码修改密码
export function changePass(phonenumber, smsCode, updatePassword) {
  return request({
    method: "put",
    url:
      `pc/selfInfo/updatePwdBySmsCode` +
      `?phonenumber=${phonenumber}&smsCode=${smsCode}&updatePassword=${updatePassword}`,
  });
}
//判断用户是否存在
// /pc/selfInfo/getUserExistsFlag/{phoneNumber}
export function ExistsUsers(phoneNumber) {
  console.log(phoneNumber);
  return request({
    method: "get",
    url: `pc/selfInfo/getUserExistsFlag/${phoneNumber} `,
  });
}

//验证码登录
export function loginCode(form) {
  return request({
    method: "POST",
    url: `/app/login-code`,
    data: form,
  });
}
//发送验证码
export function getmsmcode(phoneNumber) {
  return request({
    method: "get",
    url: `/pc/selfInfo/sendSmsCode/${phoneNumber}`,
    // headers: {
    //   isToken: false
    // }
  });
}
//注册用户
export function adduser(form) {
  return request({
    method: "post",
    url: "/pc/selfInfo/register",
    data: form,
  });
}
//登录用户
export function login(form) {
  return request({
    method: "post",
    url: "/app/login-mobile",
    data: form,
  });
}

