import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/style.scss";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import animate from 'animate.css'
import 'quill/dist/quill.core.css' 
import 'quill/dist/quill.snow.css' 
import 'quill/dist/quill.bubble.css'
Vue.prototype.$baseurl = '';
Vue.use(ElementUI);
Vue.config.productionTip = false;
let vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vue;